/* Banner.css */

.slide-in {
  animation: slideIn 1s ease-in-out;
}

.background {
  background-image: url('../../../images/banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}